export enum LinkType {
  CDL_LIFE = 'CDL_LIFE',
  COMPANY_WEBSITE = 'COMPANY_WEBSITE',
  CRAIGSLIST = 'CRAIGSLIST',
  CUSTOM = 'CUSTOM',
  FACEBOOK = 'FACEBOOK',
  INDEED = 'INDEED',
  KJ_MEDIA = 'KJ_MEDIA',
  LINKEDIN = 'LINKEDIN',
  M3TRAFFIC = 'M3TRAFFIC',
  RANDALL_REILLY = 'RANDALL_REILLY',
  RECRUITER = 'RECRUITER',
  REFERRAL = 'REFERRAL',
  TRUCK_DRIVERS_USA = 'TRUCK_DRIVERS_USA',
  TRUCKERS_REPORT = 'TRUCKERS_REPORT',
  TRUCKER_MEDIA_GROUP = 'TRUCKER_MEDIA_GROUP',
  DATA_MIGRATION = 'DATA_MIGRATION',
}

export enum LinkTypeDisplay {
  CDL_LIFE = 'CDL Life',
  COMPANY_WEBSITE = 'Company Website',
  CRAIGSLIST = 'Craigslist',
  CUSTOM = 'Custom',
  FACEBOOK = 'Facebook',
  INDEED = 'Indeed',
  KJ_MEDIA = 'KJ Media',
  LINKEDIN = 'LinkedIn',
  M3TRAFFIC = 'M3Traffic',
  RANDALL_REILLY = 'Randall Reilly',
  REFERRAL = 'Referral',
  RECRUITER = 'Recruiter',
  TRUCK_DRIVERS_USA = 'Truck Drivers USA',
  TRUCKERS_REPORT = "Trucker's Report",
  TRUCKER_MEDIA_GROUP = 'Trucker Media Group',
  DATA_MIGRATION = 'Data Migration',
}
